<template>
  <div class="User-Group">
    <div class="page-container-table">
      <!--=============== Bắt đầu phần header trên cùng================= -->
      <button-all-header
        :contentBtnAdd="'Thêm nhóm người dùng'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteMore"
        @clickDowloadSample="downloadSampleUserGroup"
        @clickExportExcel="downloadExcelAll"
        @importFile="importFileExcel($event)"
        @clickAdd="$bvModal.show('CreateUserGroup')"
        @search="search($event)"
      />
      <!--=============== Kết thúc phần header trên cùng================= -->
      <!--=================== Bắt đầu phần bảng==================== -->
      <div class="User-Group__main__table">
        <!--================================= Bảng vue good table ====================== -->
        <vue-good-table
          :columns="columns"
          style-class="vgt-table"
          :rows="rows"
          :sort-options="{
            enabled: false,
            initialSortBy: [
              {field: 'name', type: 'asc'},
              {field: 'code', type: 'desc'}
            ],
          }"
          :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
          @on-selected-rows-change="selectRowTable"
        >
          <!--=================== chỉnh sửa phần chức năng trong bảng==================== -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'cn'">
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="EditIcon"
                size="18"
                class="table__chucnang__icon"
                @click="edit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="table__chucnang__icon"
                style="margin-left: 14px"
                @click.stop="handleClickDeleteButton(props.row.id)"
              />
            </span>
          </template>
          />
        </vue-good-table>
      </div>
      <!--=================== Kết thúc phần bảng=================== -->
      <div class="User-Group__main__footer pt-5">
        <my-pagination
          :totalItems="totalPages || 0 "
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </div>
    </div>
    <modal-delete
      :id="'delete-modal'"
      :comboBoxDel="allPosition"
      :name="'Nhóm người dùng'"
      @accept="handelDelete"
    />
    <Create
      id="CreateUserGroup"
      :title="modeType === 'add' ? 'Thêm nhóm người dùng' : 'Chỉnh sửa nhóm người dùng'"
      @callAPI="create"
      @textAdd="textAdd"
    />
  </div>
</template>

<script>
// =====================================================Phân import file ======================================//
import VSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import {
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-unresolved
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import Search from '@/components/search/Search.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalDelete from './components/ModalDelete.vue'
// api
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import Create from './Create.vue'

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    MyPagination,
    Search,
    VSelect,
    ButtonAllHeader,
    ModalDelete,
    Create,
    VBTooltip,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  //= =========================== dữ liệu data ==========================//
  data() {
    return {
      arrayExcel: ['code', 'name', 'description'],
      customSelectDel: {
        required: 'Lựa chọn nhóm người dùng thay thế là bắt buộc',
      },
      showBtnMultiDelete: false,
      selectTable: '',
      deleteModel: {
        id: '',
        groupUserId: '',
      },
      allPosition: '',
      currentPage: 1, // ========================trang hiện tại==============================//
      dataTable: '', // ============mảng trả về dữ liệu của bảng để hiển thị ===============//
      selectDown: [5, 10, 20], //= ==========Lựa chọn số trang hiên thị =====================//
      perpage: 5, //= ====================  Số trang hiển thị trên bảng =========================//
      totalPages: '', // =====================  Tổng só trang   ====================================//
      valueInput: '', //= =======================Dữ liệu từ input seach ==============================//
      // currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      columns: [
        {
          label: 'Mã nhóm',
          field: 'code',
          tdClass: 'style-code',
        },
        {
          label: 'Tên nhóm',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          tdClass: 'table__tool',
          thClass: 'table__tool',
          width: '175px',
        },
      ],
      rows: [
      ],
      modeType: 'add',
    }
  },
  // eslint-disable-next-line vue/order-in-components
  // =====================================Phần Created=================================//
  async created() {
    await this.fetchdata()
  },
  // =====================================Phần methods ================================//
  methods: {
    ...mapActions('userGroup', ['downloadSampleUserGroup', 'getApiExcelUserGroup', 'downloadExcelAll']),
    // ========================================================action api=============================================//
    // lấy danh sách data
    // eslint-disable-next-line consistent-return
    async fetchUserGroup(data) {
      try {
        const resuft = await axiosApiInstance.get(ConstantsApi.LIST_USER_GROUP, { params: data })
        if (resuft.status === 200) {
          return resuft.data
        }
      } catch (e) {
        return e.response
      }
    },
    // lấy combobox xóa
    // eslint-disable-next-line consistent-return
    async getAllComboBox(id) {
      try {
        const data = JSON.stringify(id)
        const resuft = await axiosApiInstance.get(ConstantsApi.GET_ALL_COMBOBOX, { params: { ids: data } })
        if (resuft.status === 200) {
          return resuft
        }
      } catch (e) {
        return e.response
      }
    },
    // xóa file
    async deleteUserGroup(model) {
      try {
        this.$showAllPageLoading()
        const data = await axiosApiInstance.post(`${ConstantsApi.DELETE_USER_GROUP}?groupUserId=${model.groupUserId}`, model.id)
        if (data.status === 200) {
          this.$hideAllPageLoading()
          return {
            oke: true,
          }
        }
        return {
          oke: false,
        }
      } catch (error) {
        this.$hideAllPageLoading()
        return {
          oke: false,
        }
      }
    },
    // =========================================================action handle ==========================================
    async fetchdata() {
      this.$showLoading()
      const datafetchUserGroup = await this.fetchUserGroup(this.urlQuery)
      this.rows = datafetchUserGroup.data.pageLists
      this.totalPages = datafetchUserGroup.data.totalRecord
      this.$hideLoading()
    },
    // --phân trang
    async handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      await this.fetchdata()
    },
    // --tìm kiếm
    async search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        await this.fetchdata()
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        await this.fetchdata()
      }
    },
    // --import excel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    // --Kiểm tra tính hợp lệ của excel
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelUserGroup(model)
      await this.$router.push({ name: 'User-Group-List' })
    },

    async create() {
      await this.fetchdata()
    },

    edit(id) {
      this.modeType = 'edit'
      this.$bvModal.show('CreateUserGroup')
      this.$router.push({ name: 'User-Group-edit', params: { id } })
    },
    // chọn hàng table
    selectRowTable(val) {
      this.deleteModel.id = val.selectedRows.map(e => e.id)
      this.showBtnMultiDelete = this.deleteModel.id.length > 0
    },
    // ấn vào nút xóa
    async handleClickDeleteButton(id) {
      const comboBox = await this.getAllComboBox([id])
      this.allPosition = comboBox.data.data
      this.deleteModel.id = [id]
      this.$bvModal.show('delete-modal')
    },
    // Ấn nút xóa nhiều
    async deleteMore() {
      const comboBox = await this.getAllComboBox(this.deleteModel.id)
      this.allPosition = comboBox.data.data
      this.$bvModal.show('delete-modal')
    },
    async handelDelete(e) {
      this.deleteModel.groupUserId = e
      const response = await this.deleteUserGroup(this.deleteModel)
      if (response.oke) {
        await this.fetchdata()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo', variant: 'success', toaster: this.$toastPosition, solid: true,
        })
      } else {
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
        })
      }
    },
    textAdd() {
      this.modeType = 'add'
    },
  },

}
</script>

<style lang="scss">
// .User-Group {

//     &__footer {
//       margin-top: 77px;
//       padding: 20px;
//       &__left {
//         display: flex;
//         align-items: center;
//         &__item {
//           margin-right: 10px;
//         }
//       }
//     }
//   }

// label,
// p {
//   margin: 0px;
// }
.style-code span {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
  padding: 1px 9px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #227FF4;
}
.table__tool{
  width: 150px;
  text-align: center;
}
</style>
