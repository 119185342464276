<template>
  <div>
    <b-modal
      :id="id"
      :hide-footer="true"
      centered
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      :title="title"
      @hidden="close"
    >
      <div class="form-input">
        <validation-observer ref="rule">
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputCode"
              >Mã nhóm người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Mã nhóm người dùng là bắt buộc'}"
              >
                <b-form-input
                  id="basicInputCode"
                  v-model=" dataValue.code "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="addwork__item">
            <b-form-group>
              <label
                for="basicInputName"
              >Tên nhóm người dùng <span class="required">(*)</span></label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                :custom-messages="{required: 'Tên nhóm người dùng là bắt buộc',}"
              >
                <b-form-input
                  id="basicInputName"
                  v-model=" dataValue.name "
                  :state="errors.length > 0 ? false:null"
                  value=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div>
            <label for="textarea-default">Mô tả</label>
            <b-form-textarea
              id="textarea-default"
              v-model="dataValue.description"
              placeholder=""
              rows="3"
              no-resize
            />
          </div>
        </validation-observer>
      </div>
      <div class="action">
        <b-button
          v-if="!isNotification"
          variant="outline-danger mr-1 "
          @click="cancel"
        >
          Hủy bỏ
        </b-button>
        <b-button
          variant="primary"
          class="btn-left"
          @click="accept"
        >
          Lưu lại
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCardText, BButton, BFormGroup, BForm, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BCardText,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BCol,
    BFormInput,
    BFormTextarea,
    ToastificationContent,
  },
  props: {
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    variant: {
      type: String,
      default: 'primary',
    },
    id: {
      type: String,
      default: 'confirm-modal',
    },
    // nếu là thông báo không có nút cancel
    isNotification: {
      type: Boolean,
      default: false,
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      allPosition: [
        {
          name: 'Another',
          title: 'Khác',
        },
        {
          name: 'Manage',
          title: 'Nhà quản lý',
        },
        {
          name: 'HighLevel',
          title: 'Chuyên mô kỹ thuật bật cao',
        },
        {
          name: 'MidLevel',
          title: 'Chuyên môn kỹ thuật bật trung',
        },
      ],
      dataValue: {
        code: '',
        name: '',
        description: '',
      },
      checkCodeDT: '',
      checkNameDT: '',
    }
  },
  computed: {
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.getDataEdit()
      }
    },
  },
  methods: {
    //* **lấy dữ liệu edit** */
    async getDataEdit() {
      const resuft = await axiosApiInstance.get(`${ConstantsApi.FETCH_USER_GROUP_TO_ID}${this.$route.params.id}`)
      if (resuft.status === 200) {
        this.dataValue.code = resuft.data.code
        this.dataValue.name = resuft.data.name
        this.dataValue.description = resuft.data.description
      }
    },
    // eslint-disable-next-line consistent-return
    async checkCode() {
      try {
        const res = await axiosApiInstance.get('GroupUser/existCode', { params: { code: this.dataValue.code, id: this.$route.params.id } })
        if (res.status === 200) {
          this.checkCodeDT = res.data
          if (res.data === true) {
            this.$root.$bvToast.toast('Mã bị trùng', {
              title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
            })
          }
        }
      } catch (e) {
        return {
          oke: false,
        }
      }
    },
    //* **Kiểm tra tên trùng** */
    // eslint-disable-next-line consistent-return
    async checkName() {
      try {
        const res = await axiosApiInstance.get('GroupUser/existName', { params: { name: this.dataValue.name, id: this.$route.params.id } })
        if (res.status === 200) {
          this.checkNameDT = res.data
          if (res.data === true) {
            this.$root.$bvToast.toast('Tên bị trùng', {
              title: 'Thông báo', variant: 'danger', toaster: this.$toastPosition, solid: true,
            })
          }
        }
      } catch (e) {
        return {
          oke: false,
        }
      }
    },
    //* **phần thêm** */
    async add() {
      try {
        this.$showAllPageLoading()
        const resuft = await axiosApiInstance.post(ConstantsApi.CREATE_USER_GROUP, this.dataValue)
        if (resuft.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async edit() {
      try {
        const res = await axiosApiInstance.put(ConstantsApi.CREATE_USER_GROUP, this.dataValue)
        if (res.status === 200) {
          this.$root.$bvToast.toast('Sửa thành công!', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      } catch (e) {
        this.$root.$bvToast.toast('Sửa không thành công!', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
    async accept() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          await this.checkName()
          await this.checkCode()
          if (this.checkNameDT === false && this.checkCodeDT === false) {
            if (this.$route.params.id) {
              this.dataValue.id = this.$route.params.id
              await this.edit()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            } else {
              await this.add()
              this.$bvModal.hide(this.id)
              this.$emit('callAPI')
            }
          }
        }
      })
    },
    close() {
      this.dataValue = {
        code: '',
        name: '',
        description: '',
        requirement: '',
        positionGroup: '',
      }
      if (this.$route.params.id) {
        this.$router.push({ name: 'User-Group' })
      }
      this.$emit('textAdd')
    },
    cancel() {
      this.dataValue = {
        code: '',
        name: '',
        description: '',
        requirement: '',
        positionGroup: '',
      }
      if (this.$route.params.id) {
        this.$router.push({ name: 'User-Group' })
      }
      this.$bvModal.hide(this.id)
      this.$emit('cancel')
      // this.$router.push({ name: 'position-job' })
    },
  },
}
</script>
<style scoped>
.action{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0px 20px 20px;
  margin-top: 20px;
  border-top: 1px solid #CBCDD2 ;
}
</style>
